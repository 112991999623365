import React from 'react';

import { Helmet } from 'react-helmet';

import Header from 'components/common/header';
import Main from 'components/common/main';
import Footer from 'components/common/footer';
import { PageMeta } from 'constants/page_meta';
import { TitleBar } from 'lib/TitleBar';
import {
	PageContainer,
	SectionTitle,
	ContentText,
	StaticList,
	SectionSubTitle,
	LinkButton,
} from './styles';
import routes from 'config/routes';

const meta = PageMeta.getStatic('how_it_works');

const HowItWorks = () => {
	return (
		<Main>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<Header searchable />
			<TitleBar title="How it works"></TitleBar>
			<PageContainer>
				<SectionTitle>How DigsConnect works</SectionTitle>
				<ContentText>
					Our new booking chat feature allows you as the tenant and landlord to chat directly on our
					local chat system, as soon as you've sent or received a booking enquiry.
				</ContentText>
				<SectionTitle>Landlord</SectionTitle>
				<ContentText>
					Follow these steps to find your ideal tenant, and only pay us on success (move-in). No fee
					to list.
				</ContentText>
				<SectionSubTitle>1. Sign up</SectionSubTitle>
				<ContentText indent>
					Click the sign-up button at the top right of the homepage, fill in your details to create
					a basic profile and voilà, you’re in. We validate your contact details for security
					reasons.
				</ContentText>
				<SectionSubTitle>2. Add a Digs</SectionSubTitle>
				<ContentText indent>
					It’s time to add your property to the platform so the requests start rolling in. Click the
					"+ Create a listing" button from the homepage, describe your property in all its glory,
					and add some fantastic high-quality pictures of each of the rooms to ensure potential
					tenants get the full picture of what your property has to offer.
				</ContentText>
				<ContentText indent>
					Click the button below to get all the tips and tricks for creating the perfect listing
					<span role="img" aria-label="">
						👇
					</span>
				</ContentText>

				<LinkButton to={routes.landlord_resources}>Landlord resources</LinkButton>

				<SectionSubTitle>3. Requests start rolling in</SectionSubTitle>
				<ContentText indent>
					Once you’ve added your property, you’ll start receiving booking enquiries from prospective
					tenants. You will receive a booking form and a message in the chat from the prospective
					tenant. Remember that you have only 48 hours to reply to this message or the enquiry will
					expire.
				</ContentText>
				<ContentText indent>
					You’ll get an email and SMS every time you get an enquiry or new message. Then the timer
					starts! You only have 48 hours to get back to the tenant, or the booking enquiry will
					expire. We have found that the faster the reply, the higher the likelihood of the deal
					being successful.
				</ContentText>
				<ContentText indent>
					We’ve designed a booking form that tenants complete which includes:
				</ContentText>

				<StaticList indent={2}>
					<li>A profile picture of the tenant</li>
					<li>Name</li>
					<li>Ideal move-in date</li>
					<li>Dream lease period</li>
					<li>Whether they are a student or a young adult</li>
					<li>Where they studying (if they are)</li>
					<li>Whether they are an undergrad or postgrad (if applicable)</li>
					<li>Who their rent payer is</li>
				</StaticList>

				<ContentText indent>
					By adding this requirement we are able to screen for quality and intent, saving you time
					trolling through hundreds of non-starter enquiries from other platforms. We then ask them
					to send the first message in the Digs chat to ask any questions they may have. You can
					then chat directly with them!
				</ContentText>
				<SectionSubTitle>4. Chat away!</SectionSubTitle>
				<ContentText indent>
					Feel free to chat for as long (or short) as you’d like with the tenant. Make sure it’s a
					good fit! Our support team will follow up with both you and the tenant to check-in and
					provide support wherever needed.
				</ContentText>
				<SectionSubTitle>5. Move-in</SectionSubTitle>
				<ContentText indent>
					Once you’re both happy and confirm that the match is official, our support team will
					confirm the move in with the tenant and then pop you an invoice for the Digs service fee.
				</ContentText>
				<ContentText indent>
					We also have a full time team who are available to guide you through the entire process!
					We only charge on success, so if you don’t find a tenant that meets your requirements then
					you don’t pay.
				</ContentText>
				<SectionTitle>Tenant</SectionTitle>
				<ContentText>Follow the steps below to get started.</ContentText>
				<SectionSubTitle>1. Sign up</SectionSubTitle>
				<ContentText indent>
					Click the sign-up button at the top right of the homepage, fill in your details to create
					a basic profile and voilà, you’re in. We validate your contact details for security
					reasons.
				</ContentText>
				<SectionSubTitle>2. Find a digs</SectionSubTitle>
				<ContentText indent>
					Either enter the university/college or location of your choice on the homepage search bar
					to view properties wherever you dream. Browse through our amazing selection of
					accommodation, whether you’re looking for a room or a whole house. Plus, we have a bunch
					of filters you can use to make your search easier, from room types to price.
				</ContentText>
				<SectionSubTitle>3. Send your request</SectionSubTitle>
				<ContentText indent>
					Once you’ve found the perfect spot, you’ll need to click “Enquire” to get the ball
					rolling. Complete your short booking enquiry form and write a message to the landlord
					asking any questions you may have, or specifying anything in particular that you want to
					share. This will be the first message in the chat with the landlord.
				</ContentText>
				<SectionSubTitle>4. A short wait</SectionSubTitle>
				<ContentText indent>
					The landlord has 48 hours to get back to you, but, usually, get a reply much quicker. They
					will reply with a message on the Digs Chat. We’ll send you an email and SMS to make sure
					that you don’t miss it.
				</ContentText>
				<SectionSubTitle>5. Chat away!</SectionSubTitle>
				<ContentText indent>
					Now that you’ve connected, you can chat freely through the Digs Chat system to ask any
					questions or to arrange a viewing. From there you can chat till you’re totally happy with
					the place, and then you can conclude the deal. Easy as that.
				</ContentText>
				<ContentText indent>
					If you don’t find something you totally love, then add an alert from the Find a Digs page
					and whenever a property is added that meets your requirements, we’ll send you an email
					with a link to the listing.
				</ContentText>
				<ContentText indent>
					Contact support <a href="mailto:support@digsconnect.com ">support@digsconnect.com </a> or
					call/WhatsApp us on <a href="tel:0659313808">065 931 3808</a>.
				</ContentText>
			</PageContainer>
			<Footer />
		</Main>
	);
};

export default HowItWorks;
